'use client'

import { useState, KeyboardEvent } from 'react'
import { Icon } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import BuyerProtectionModal from 'components/BuyerProtectionModal'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { onA11yKeyDown } from 'data/utils/a11y'
import { Screen } from 'constants/tracking/screens'

type Props = {
  itemId: number
  bundleId?: string | null
  isSellerBusiness?: boolean
}

const ItemPricingDetailsIcon = ({ itemId, isSellerBusiness = false, bundleId = null }: Props) => {
  const translate = useTranslate(
    isSellerBusiness ? 'item_pricing_details_modal.pro' : 'item_pricing_details_modal.regular',
  )

  const { track } = useTracking()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const trackIconClick = () => {
    if (bundleId) {
      track(
        clickEvent({
          screen: Screen.BundleSummary,
          target: ClickableElement.ServiceFeeInfo,
          targetDetails: JSON.stringify({ bundle_id: bundleId }),
        }),
      )
    } else {
      track(
        clickEvent({
          target: ClickableElement.ServiceFeeInfo,
          targetDetails: JSON.stringify({ item_id: itemId }),
        }),
      )
    }
  }

  const handleIconClick = () => {
    setIsModalVisible(true)

    trackIconClick()
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    onA11yKeyDown(event, handleIconClick)
  }

  const renderContent = () => (
    <>
      <div
        className="u-flexbox u-align-self-center u-cursor-pointer"
        data-testid="item-pricing-details-icon"
        role="button"
        tabIndex={0}
        onClick={handleIconClick}
        onKeyDown={handleKeyDown}
      >
        <Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel4} title={translate('title')} />
      </div>
      <BuyerProtectionModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
        sellerIsBusinessUser={isSellerBusiness}
      />
    </>
  )

  return renderContent()
}

export default ItemPricingDetailsIcon
