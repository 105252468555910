'use client'

import { useState, KeyboardEvent } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Spacer, Text } from '@vinted/web-ui'
import { CheckmarkShieldOutline16, Shield12 } from '@vinted/monochrome-icons'

import { onA11yKeyDown } from 'data/utils/a11y'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import ItemPriceBreakdownModal from 'components/ItemPriceBreakdownModal'
import DetailedItemPriceBreakdownModal from 'components/DetailedItemPriceBreakdownModal'
import { getLocale } from 'state/intl/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { clickEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import { CurrencyAmountModel } from 'types/models'

import ServiceTitle from './ServiceTitle'
import SellerText from './SellerText'

type Props = {
  itemId: number
  isItemOwner?: boolean
  itemPrice: CurrencyAmountModel
  isSellerBusiness: boolean
  totalItemPrice?: CurrencyAmountModel | null
  serviceFee?: CurrencyAmountModel | null
  shouldTrimTitle?: boolean
  shouldMinimiseTitle?: boolean
  onModalOpen?: () => void
  onModalClose?: () => void
  detailedBreakdown?: {
    itemPhotoSrc?: string | null
    escrowFees?: EscrowFeesModel
    itemTitle: string
    transactionId?: number
  } | null
  showInclinePrice?: boolean
}

const ServiceFeeIncludedTitle = ({
  itemId,
  totalItemPrice,
  itemPrice,
  serviceFee,
  isItemOwner,
  isSellerBusiness,
  shouldTrimTitle,
  shouldMinimiseTitle,
  onModalOpen,
  onModalClose,
  detailedBreakdown,
  showInclinePrice = true,
}: Props) => {
  const [isPriceBreakdownModalOpen, setIsPriceBreakdownModalOpen] = useState(false)

  const translate = useTranslate('item.includes_buyer_protection')
  const { track } = useTracking()

  const locale = useSelector(getLocale)

  const isProminenceV5Enabled = useSelector(getIsFeatureSwitchEnabled('bpf_prominence_v5'))

  const formattedItemPrice = formatCurrencyAmount(itemPrice, locale)
  const formattedServiceFee = serviceFee?.amount ? formatCurrencyAmount(serviceFee, locale) : null
  const formattedTotalPrice = totalItemPrice?.amount
    ? formatCurrencyAmount(totalItemPrice, locale)
    : null

  const userTypeSuffix = isSellerBusiness ? '_pro' : ''

  const handleClick = () => {
    track(
      clickEvent({
        target: ClickableElement.PricingDetails,
        targetDetails: JSON.stringify({ item_id: itemId }),
      }),
    )

    if (isPriceBreakdownModalOpen) onModalClose?.()
    else onModalOpen?.()

    setIsPriceBreakdownModalOpen(prevState => !prevState)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    onA11yKeyDown(event, handleClick)
  }

  const renderTitle = () => {
    if (shouldTrimTitle || isItemOwner) {
      return translate(`title_trimmed${userTypeSuffix}`, { price: formattedTotalPrice })
    }

    return translate(`title${userTypeSuffix}`, { price: formattedTotalPrice })
  }

  const textButtonProps = {
    role: 'button',
    tabIndex: 0,
    'aria-label': translate('title', { price: formattedTotalPrice }),
    onKeyDown: handleKeyDown,
    onClick: handleClick,
  }

  const titleTestId = isSellerBusiness
    ? 'service-fee-included-title-pro'
    : 'service-fee-included-title'

  const renderInlinePrice = () => (
    <div className="u-flexbox u-align-items-flex-start">
      <div
        className="u-cursor-pointer u-flexbox u-align-items-center u-flex-wrap"
        {...textButtonProps}
      >
        <div className="u-flexbox u-align-items-baseline u-flex-wrap">
          <Text
            type={shouldMinimiseTitle ? Text.Type.Subtitle : Text.Type.Body}
            text={formattedTotalPrice}
            clickable
            underline={false}
            as="p"
          />
          <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
          <ServiceTitle
            isItemOwner={isItemOwner}
            isSellerBusiness={isSellerBusiness}
            shouldMinimiseTitle={shouldMinimiseTitle}
            shouldTrimTitle={shouldTrimTitle}
          />
        </div>
        <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
        <Icon color={Icon.Color.Primary} name={Shield12} testId="service-fee-included-icon" />
        {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
      </div>
    </div>
  )

  const renderNonInlinePrice = () => (
    <div
      {...textButtonProps}
      className="u-flexbox u-align-items-flex-start u-flex-direction-column"
    >
      <Text
        as="div"
        type={Text.Type.Title}
        text={formattedTotalPrice}
        clickable
        underline={false}
        tabIndex={-1}
      />
      <div className="u-flexbox u-align-items-center u-flex-wrap">
        <ServiceTitle
          isItemOwner={isItemOwner}
          isSellerBusiness={isSellerBusiness}
          shouldMinimiseTitle={shouldMinimiseTitle}
          shouldTrimTitle={shouldTrimTitle}
        />
        <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
        <Icon color={Icon.Color.Primary} name={Shield12} testId="service-fee-included-icon" />
        {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
      </div>
    </div>
  )

  const renderProminentContent = () =>
    showInclinePrice ? renderInlinePrice() : renderNonInlinePrice()

  const renderContent = () => (
    <div className="u-flexbox u-align-items-flex-start">
      <div
        className="u-cursor-pointer u-flexbox u-align-items-center u-flex-wrap"
        {...textButtonProps}
      >
        <Text
          clickable
          underline={false}
          text={renderTitle()}
          type={shouldMinimiseTitle ? Text.Type.Caption : Text.Type.Subtitle}
          testId={titleTestId}
          as="span"
          tabIndex={-1}
        />
        <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
        <Icon
          color={Icon.Color.Primary}
          name={shouldMinimiseTitle ? Shield12 : CheckmarkShieldOutline16}
          testId="service-fee-included-icon"
        />
        {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
      </div>
    </div>
  )

  return (
    <>
      {isProminenceV5Enabled ? renderProminentContent() : renderContent()}
      {detailedBreakdown ? (
        <DetailedItemPriceBreakdownModal
          isVisible={isPriceBreakdownModalOpen}
          itemPrice={formattedItemPrice}
          onClose={handleClick}
          isSellerBusiness={isSellerBusiness}
          isItemOwner={!!isItemOwner}
          itemId={itemId}
          {...detailedBreakdown}
        />
      ) : (
        <ItemPriceBreakdownModal
          itemId={itemId}
          isVisible={isPriceBreakdownModalOpen}
          isSellerBusiness={isSellerBusiness}
          itemPrice={formattedItemPrice}
          serviceFee={formattedServiceFee}
          onClose={handleClick}
        />
      )}
    </>
  )
}

export default ServiceFeeIncludedTitle
