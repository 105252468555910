import useAbTest from 'hooks/useAbTest'
import { AbTestVariant } from 'constants/abtest'

const useEscrowFeeDiscountAbTest = () => {
  const isHvfFeesDiscountAbTestEnabled =
    useAbTest({ abTestName: 'bpf_discount_hvf_v1' })?.variant === AbTestVariant.On
  const isItemVerificationServiceDiscountAbTestEnabled =
    useAbTest({ abTestName: 'ivs_discount_free_v1' })?.variant === AbTestVariant.On

  const areEscrowFeeDiscountEnabled =
    isHvfFeesDiscountAbTestEnabled || isItemVerificationServiceDiscountAbTestEnabled

  return areEscrowFeeDiscountEnabled
}

export default useEscrowFeeDiscountAbTest
