'use client'

import { ReactNode } from 'react'
import { Cell, Text, List } from '@vinted/web-ui'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'
import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import {
  EscrowFeeBuyerProtectionModel,
  EscrowFeeItemVerificationModel,
} from 'types/models/escrow-fees'

type Props = {
  feeTitle: string
  prefix: ReactNode
  infoIcon: ReactNode
  isOptional?: boolean
  escrowFee: EscrowFeeBuyerProtectionModel | EscrowFeeItemVerificationModel
  isItemOwner: boolean
  discountNote?: string | null
  testId: string
}

const FeeDetails = ({
  feeTitle,
  prefix,
  infoIcon,
  isOptional,
  escrowFee,
  isItemOwner,
  discountNote,
  testId,
}: Props) => {
  const translate = useTranslate('item_price_breakdown_detailed')
  const locale = useSelector(getLocale)
  const { formatDate } = useIntl()

  if (!escrowFee) return null

  const {
    discountPercentage,
    isFree,
    finalPrice,
    originalPrice,
    discountStartDate,
    discountEndDate,
    lowestPrice30Days,
  } = escrowFee

  const hasDiscount = !!discountPercentage
  const shouldShowDiscount = !isItemOwner && hasDiscount

  const renderDiscountDates = () =>
    shouldShowDiscount && (
      <>
        {discountNote && (
          <List.Item>
            <span data-testid="discount-note">
              <Text theme="muted" type={Text.Type.Subtitle} text={discountNote} />
            </span>
          </List.Item>
        )}
        {discountStartDate && discountEndDate && (
          <List.Item>
            <Text
              theme="muted"
              type={Text.Type.Subtitle}
              text={translate('promotion_period', {
                dateFrom: formatDate(discountStartDate, {
                  day: 'numeric',
                  month: 'short',
                }),
                dateTo: formatDate(discountEndDate, {
                  day: 'numeric',
                  month: 'short',
                }),
              })}
            />
          </List.Item>
        )}
        {lowestPrice30Days && (
          <List.Item>
            <Text
              theme="muted"
              type={Text.Type.Subtitle}
              text={translate('lowest_price_in_30_days_header', {
                amount: formatCurrencyAmount(lowestPrice30Days, locale),
              })}
            />
          </List.Item>
        )}
      </>
    )

  const renderTitle = () => {
    const discountText = isFree ? `${translate('is_free')}` : `(-${discountPercentage as number}%)`

    return (
      <div className="u-flexbox u-align-items-center u-gap-small">
        {feeTitle}
        {shouldShowDiscount && <Text theme="warning" text={discountText} />}
        {infoIcon}
      </div>
    )
  }

  const renderDiscountAmount = () => (
    <div className="u-flexbox u-gap-small">
      <Text
        text={formatCurrencyAmount(finalPrice, locale)}
        type={Text.Type.Title}
        theme="amplified"
      />
      {shouldShowDiscount && (
        <div className="u-padding-right-small">
          <Text
            theme="muted"
            type={Text.Type.Subtitle}
            strikethrough
            text={formatCurrencyAmount(originalPrice, locale)}
          />
        </div>
      )}
    </div>
  )

  const renderOptionalLabel = () =>
    isOptional && (
      <List.Item>
        <div className="u-padding-bottom-small">
          <Text text={translate('optional')} type={Text.Type.Subtitle} theme="muted" />
        </div>
      </List.Item>
    )

  return (
    <Cell
      testId={testId}
      styling={Cell.Styling.Narrow}
      title={renderTitle()}
      prefix={prefix}
      body={
        <>
          {renderDiscountAmount()}
          {(shouldShowDiscount || isOptional) && (
            <List size={List.Size.Tight}>
              {renderOptionalLabel()}
              {renderDiscountDates()}
            </List>
          )}
        </>
      }
    />
  )
}

export default FeeDetails
